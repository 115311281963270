<template>
  <div class="system-messages">
    <div class="search-box">
      <el-input v-model="searchVal" placeholder="请输入内容搜索" style="width: 300px; margin-right: 20px">
        <el-button slot="append" icon="el-icon-search"></el-button>
      </el-input>
      <el-select v-model="labelVal" placeholder="请选择标签" @change="changeLabel">
        <el-option v-for="item in options" :key="item" :label="item" :value="item"></el-option>
      </el-select>
    </div>
    <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="60"> </el-table-column>
      <el-table-column prop="content" label="内容" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="time" label="时间" width="100"> </el-table-column>
      <el-table-column prop="label" label="标签" width="100"> </el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <el-link type="danger" :underline="false" @click="onDel(scope.$index)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "SystemMessages",
  components: {},
  props: {},
  data() {
    return {
      tableData: [
        { id: 1, content: "【公告】您的任务“市场分析”即将到期，请及时完成任务", time: "10分钟前", label: "" },
        { id: 2, content: "【公告】您的任务“直播售卖”即将到期，请及时完成任务", time: "1小时前", label: "一般" },
        { id: 3, content: "【公告】您的任务“创建直播”即将到期，请及时完成任务", time: "1天前", label: "重要" },
        { id: 4, content: "【公告】您的任务“直播推广”即将到期，请及时完成任务", time: "14天前", label: "紧急" },
        { id: 5, content: "【公告】您的任务“优惠券管理”即将到期，请及时完成任务", time: "1月前", label: "备忘" },
      ],
      multipleSelection: [],
      searchVal: "",
      labelVal: "",
      options: ["紧急", "一般", "重要", "备忘"],
    };
  },
  computed: {},
  methods: {
    changeLabel(val) {
      if (!this.multipleSelection.length) {
        this.labelVal = "";
        return this.$message.error("请先选择公告！");
      }
      this.multipleSelection.map((item) => (item.label = val));
      this.tableData = [...this.tableData, ...this.multipleSelection];
      this.tableData = Array.from(new Set(this.tableData));
      setInterval(() => {
        this.labelVal = "";
      }, 1000);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    onDel(index) {
      this.tableData.splice(index, 1);
    },
  },
};
</script>

<style scoped lang="scss">
.system-messages {
  box-sizing: border-box;
  height: 100%;
  padding: 30px;
  .search-box {
    margin-bottom: 20px;
    display: flex;
  }
}
</style>
